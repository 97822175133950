// Package Imports
import React, { useState } from 'react';
import { useUpdate, pickNotDeprecated, useTranslate } from '@refinedev/core';
import { Layout } from 'antd';

// Project Imports
import ConfigStyle from 'core/constants/ConfigStyle';
import { HeaderProps } from 'components/header/interface';
import { useTokenContext } from 'contexts/resource/ResourceContext';
import apiEndpoint from 'core/common/apiEndPoints';
import Notification from 'components/header/socketNotification';
import openNotificationWithIcon from 'core/utils/notification';
import constants from 'core/constants/Constants';
import HeaderContainerView from 'components/header/headerContainerView';
import { useSocketNotificationContext } from 'contexts/resource/socketNotificationContext/SocketNotificationContext';

const Header: React.FC<HeaderProps> = ({ isSticky, sticky }) => {
  const { userDetails } = useTokenContext();
  const { notificationMessages } = useSocketNotificationContext();

  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const { mutate } = useUpdate();
  const t = useTranslate();

  const saveLang = (lang: string) => {
    mutate({
      resource: apiEndpoint.updateUserRoleProgramDetails,
      values: {
        selected_language: lang,
      },
      id: userDetails?.unique_id,
    });
  };

  const saveOrg = (org: string) => {
    mutate(
      {
        resource: apiEndpoint.updateUserRoleProgramDetails,
        values: {
          organization_id: org,
        },
        id: userDetails?.unique_id,
        successNotification: false,
        errorNotification: false,
      },
      {
        onSuccess: (data) => {
          window.location.reload();
          const message =
            data?.data?.message || t('notifications.calculationSuccess');
          openNotificationWithIcon(
            constants.success,
            t('notifications.mutationSuccess'),
            message
          );
        },
        onError(error) {
          const message = error?.message || t('notifications.calculationError');
          openNotificationWithIcon(
            constants.error,
            t('notifications.mutationError'),
            message
          );
        },
      }
    );
  };

  const saveProgram = (program: string) => {
    mutate(
      {
        resource: apiEndpoint.updateUserRoleProgramDetails,
        values: {
          iprogram_id: program,
        },
        id: userDetails?.unique_id,
        successNotification: false,
        errorNotification: false,
      },
      {
        onSuccess: (data) => {
          window.location.reload();
          const message =
            data?.data?.message || t('notifications.calculationSuccess');
          openNotificationWithIcon(
            constants.success,
            t('notifications.mutationSuccess'),
            message
          );
        },
        onError(error) {
          const message = error?.message || t('notifications.calculationError');
          openNotificationWithIcon(
            constants.error,
            t('notifications.mutationError'),
            message
          );
        },
      }
    );
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const headerStyles: React.CSSProperties = {
    backgroundColor: ConfigStyle.colors.white,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 24px',
    height: '64px',
    justifyContent: 'flex-end',
    position: 'sticky',
    top: 0,
    width: '100%',
    zIndex: 20,
  };

  if (pickNotDeprecated(sticky, isSticky)) {
    headerStyles.position = 'sticky';
    headerStyles.top = 0;
    headerStyles.zIndex = 1;
  }
  const handleToggleNotifications = () => {
    if (!notificationsVisible) {
      setNotificationsVisible(!notificationsVisible);
    }
  };

  return (
    <Layout.Header style={{ ...headerStyles }}>
      <HeaderContainerView
        saveLang={saveLang}
        saveOrg={saveOrg}
        saveProgram={saveProgram}
        filterOption={filterOption}
        handleToggleNotifications={handleToggleNotifications}
        notificationsVisible={notificationsVisible}
      />
      {notificationMessages && notificationMessages?.length > 0 ? (
        <Notification
          notificationsVisible={notificationsVisible}
          setNotificationsVisible={setNotificationsVisible}
        />
      ) : null}
    </Layout.Header>
  );
};

export default Header;
