import SocketEvent from 'core/constants/SocketEvent';

const setNotificatonApi = async (
  socketInstance,
  toggleSocketNotificationsApi
): Promise<void> => {
  try {
    socketInstance.on(SocketEvent.ORGANIZATION_CREATION_EVENT_NAME, (data) => {
      toggleSocketNotificationsApi(data?.event_name);
    });
  } catch (error) {
    console.log(error);
  }
};

export default setNotificatonApi;
