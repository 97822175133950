// Package Imports
import { ReactKeycloakProvider } from '@react-keycloak/web';
import React from 'react';
import { createRoot } from 'react-dom/client';

// Project Imports
import keycloak from 'keycloak/keyCloak';
import App from 'App';
import 'i18n';
import TokenProvider from 'contexts/resource/ResourceContext';
import SocketNotificationProvider from 'contexts/resource/socketNotificationContext/SocketNotificationContext';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <TokenProvider>
      <SocketNotificationProvider>
        <ReactKeycloakProvider authClient={keycloak}>
          <React.StrictMode>
            <React.Suspense fallback="loading">
              <App />
            </React.Suspense>
          </React.StrictMode>
        </ReactKeycloakProvider>
      </SocketNotificationProvider>
    </TokenProvider>
  );
}
