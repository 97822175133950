// Package Imports
import { Badge, Button, Dropdown, Select, Space } from 'antd';
// import { MenuProps } from 'antd/lib';
import React, { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
import { useLogout } from '@refinedev/core';
import { BellOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

// Project Imports
import { useTokenContext } from 'contexts/resource/ResourceContext';
// import LanguageLabels from 'components/header/data';
import routeName from 'core/common/routeName';
import { getOrganizationName } from 'pages/profile/apiCall';
import { useSocketNotificationContext } from 'contexts/resource/socketNotificationContext/SocketNotificationContext';

// const { useBreakpoint } = Grid;

const HeaderContainerView = ({
  // saveLang,
  filterOption,
  saveOrg,
  saveProgram,
  handleToggleNotifications,
  notificationsVisible,
}: any) => {
  // const locale = useGetLocale();
  // const { i18n } = useTranslation();
  // const currentLocale = locale();
  // const changeLanguage = useSetLocale();
  const navigate = useNavigate();
  const {
    orgId,
    // setCurrentLang,
    organizationList,
    userProgramList,
    programId,
  } = useTokenContext();
  // const screens = useBreakpoint();
  const { userDetails } = useTokenContext();
  const { notificationMessages } = useSocketNotificationContext();
  const { mutate: logout } = useLogout();
  // const menuItemsLang: MenuProps['items'] = [...(i18n.languages || [])]
  //   .sort()
  //   .map((lang: string) => ({
  //     key: lang,
  //     onClick: async () => {
  //       await setCurrentLang(lang);
  //       await changeLanguage(lang);
  //       await saveLang(lang);
  //     },
  //     // icon: (
  //     //   <span style={{ marginRight: 8 }}>
  //     //     <Avatar size={16} src={imageFlag(lang)} />
  //     //   </span>
  //     // ),
  //     label: LanguageLabels[lang] || lang,
  //   }));

  const menu = {
    items: [
      {
        key: 'profile',
        label: 'Profile',
        onClick: () => navigate(routeName.profile),
      },
      {
        key: 'logout',
        label: 'Logout',
        onClick: () => logout(),
      },
    ],
  };
  const handleOrgChange = (e: string) => {
    saveOrg(e);
  };

  const handleProgramChange = (e) => {
    saveProgram(e);
  };

  function getNotificationCount(notificationmsg) {
    return notificationmsg?.filter((msg) => !msg?.read)?.length;
  }
  console.log('iprogramid header container>>', programId);

  useEffect(() => {
    if (
      userProgramList?.length > 0 &&
      !userProgramList.some((prog) => prog.value === programId)
    ) {
      handleProgramChange(userProgramList[0].value);
    }
  }, [userProgramList]);

  return (
    <Space>
      {organizationList?.length > 1 ? (
        <Select
          className="org-select"
          style={{ width: 300 }}
          value={organizationList?.length > 0 ? orgId : undefined}
          placeholder="Select Organisation"
          onChange={(e) => handleOrgChange(e)}
          showSearch
          options={organizationList}
          optionFilterProp="children"
          filterOption={filterOption}
        />
      ) : null}
      {userProgramList?.length > 1 ? (
        <Select
          className="org-select"
          style={{ width: 300 }}
          value={userProgramList?.length > 0 ? programId : null}
          placeholder="Select Program"
          onChange={(e) => handleProgramChange(e)}
          showSearch
          options={userProgramList}
          optionFilterProp="children"
          filterOption={filterOption}
        />
      ) : null}
      {/* <Dropdown
        className="multilingual"
        menu={{
          items: menuItemsLang,
          selectedKeys: currentLocale ? [currentLocale] : [],
        }}
      >
        <a
          style={{ color: 'inherit' }}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Space>
            <div
              style={{
                display: screens.lg ? 'block' : 'none',
              }}
            >
              {LanguageLabels[currentLocale]}
              <DownOutlined
                style={{
                  fontSize: '12px',
                  marginLeft: '6px',
                }}
              />
            </div>
          </Space>
        </a>
      </Dropdown> */}

      <div id="notifications">
        <Badge
          count={
            !notificationsVisible &&
            notificationMessages &&
            notificationMessages?.length > 0
              ? getNotificationCount(notificationMessages)
              : 0
          }
          className="z-10"
        >
          <BellOutlined
            onClick={handleToggleNotifications}
            style={{ fontSize: '24px', color: 'black', cursor: 'pointer' }}
          />
        </Badge>
      </div>

      <Dropdown menu={{ items: menu.items }} placement="bottomRight">
        <Space size="middle">
          <Button
            style={{
              color: 'primary',
              fontWeight: 'bold',
              borderRadius: '50%',
              backgroundColor: '#d8d8d8',
            }}
            icon={<UserOutlined className="avatar-icon" />}
          />
        </Space>
      </Dropdown>

      <div className="ml-4">
        <div className="font-semibold text-base text-gray-600">{`${userDetails?.user_first_name} ${userDetails?.user_last_name}`}</div>
        <div className="text-sm text-gray-400">
          {getOrganizationName(userDetails?.organization_id)}
        </div>
      </div>
    </Space>
  );
};

export default HeaderContainerView;
