// Project Imports
import apiEndpoint from 'core/common/apiEndPoints';
import axiosInstance from 'providers/axiosInstance/axiosInstance';
import useLogger from 'core/utils/useLogger';

const fetchPrograms = async (
  programId: any,
  setUserProgramList: React.Dispatch<React.SetStateAction<any[]>>
): Promise<void> => {
  try {
    const programApiUrl = `${process.env.REACT_APP_API_URL_ORG}/${apiEndpoint.getProgramRoles}/`;

    const programApiResponse = await axiosInstance.get(`${programApiUrl}`);

    const responseData = (programApiResponse.data as any)?.results || [];
    const data = responseData.map((item) => ({
      label: item.iprogram_name,
      value: item.iprogram_unique_id,
      ...item,
    }));
    await setUserProgramList(data);

    if (data?.length > 0) {
      axiosInstance.defaults.headers.common = {
        ...axiosInstance.defaults.headers.common,
        'iprogram-id': programId,
      };
    }
  } catch (error) {
    if (error?.response?.data) {
      const logger = useLogger();
      setUserProgramList([]);
      logger('User roles program details error :: ', error?.response?.data);
    }
  }
};

export default fetchPrograms;
