/* eslint-disable @typescript-eslint/no-floating-promises */
// Package Imports
import { createContext, useContext, useEffect, useState } from 'react';

// Project Imports

import SocketEvent from 'core/constants/SocketEvent';
import { useTokenContext } from 'contexts/resource/ResourceContext';
import { SocketNotificationContextType } from 'contexts/resource/socketNotificationContext/SocketNotificationContextType';
import fetchNotificatons from 'core/utils/fetchNotificatons';
import setNotificatonApi from 'core/utils/setNotificationApi';

const SocketNotificationContext = createContext<SocketNotificationContextType>({
  setNotificationMessages: () => {},
  notificationMessages: [],
  toggleOrgCreation: false,
  settoggleOrgCreation: () => {},
});

export function useSocketNotificationContext() {
  return useContext(SocketNotificationContext);
}

const SocketNotificationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { userDetails, socketInstance } = useTokenContext();
  const [toggleOrgCreation, settoggleOrgCreation] = useState(false); // org creation
  const [notificationMessages, setNotificationMessages] = useState<any>(() => {
    const notificationList = localStorage.getItem('notifications');
    return (notificationList && JSON.parse(notificationList)) || [];
  });

  const toggleSocketNotificationsApi = (eventName) => {
    switch (eventName) {
      case SocketEvent.ORGANIZATION_CREATION_EVENT_NAME:
        settoggleOrgCreation(!toggleOrgCreation);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (socketInstance && userDetails) {
      fetchNotificatons(setNotificationMessages, userDetails, socketInstance);
      // call api whenever event trigger
      setNotificatonApi(socketInstance, toggleSocketNotificationsApi);
    }
  }, [socketInstance]);

  return (
    <SocketNotificationContext.Provider
      value={{
        toggleOrgCreation,
        settoggleOrgCreation,
        notificationMessages,
        setNotificationMessages,
      }}
    >
      {children}
    </SocketNotificationContext.Provider>
  );
};

export default SocketNotificationProvider;
